/* General style */
body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.auth-page {
  overflow: hidden;
}
* {
  box-sizing: border-box;
}

html,
body,
#root {
  /* height: 100vh;
    width: 100vw;
    overflow: auto; */
}

#root {
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Ultilities */
.fw-normal {
  font-weight: normal !important;
}
div[data-testId='custom-popup'] {
  overflow: visible;
  margin: 20px -14px 20px 0;
}

div[role="menu"] [data-item-title='true'] {
  font-family: 'Arial', sans-serif !important;
  color: #000;
}

tr[testId='table-row'] {
  border-bottom: 1px solid #EFEFEF;
}
